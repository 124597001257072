import './App.css';
import { Container } from '@mui/material';
import TitlebarBelowMasonryImageList from './TitlebarBelowMasonryImageList';
import DenseAppBar from './DenseAppBar';
import { useState, useEffect } from 'react';
import axios from 'axios';
import LocationOverlay from './LocationOverlay';
import Login from './Login';

function Main() {
  const [hasLocation, setHasLocation] = useState(false);
  const [isInAmerica, setIsInAmerica] = useState(false);
  const [showInstructions, setShowInstructions] = useState(false);
  const [lastPosition, setLastPosition] = useState(null);

  // Function to check if the user is in America
  const checkAmerica = (latitude, longitude) => {
    return (
      latitude >= 24.396308 &&
      latitude <= 49.384358 &&
      longitude >= -124.733056 &&
      longitude <= -66.93457
    );
  };

  const watchPositionContinuously  = () => {
    if (navigator.geolocation) {
        console.log(new Date());
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          // Check if the user is in America
          const inAmerica = checkAmerica(latitude, longitude);
          setIsInAmerica(inAmerica);
          
          // Set hasLocation to true regardless of the location to prevent multiple prompts
          setHasLocation(true);
          setShowInstructions(false);

          // If user is in America and position has changed significantly, send location data to the server
          if (inAmerica) {
            setLastPosition({ latitude, longitude });
            sendLocationData(latitude, longitude);
          }
        },
        (error) => {
          // Check for specific errors
          if (error.code === error.PERMISSION_DENIED) {
            setHasLocation(false);
          }
        },
        {
          enableHighAccuracy: true,
          timeout: 1000,
          maximumAge: 0,
          message: 'We would like to access your location to provide personalized recommendations. Is that okay?'
        }
      );
    } else {
      alert('Geolocation is not supported by this browser.');
    }
  };

  // Function to send location data to the server
  const sendLocationData = async (latitude, longitude) => {
    try {
      const ipResponse = await axios.get('https://api.ipify.org?format=json');
      const ip = ipResponse.data.ip;
      await axios.post(process.env.REACT_APP_API_ENDPOINT, {
        coordinates: [{ latitude, longitude, time: new Date() }],
        ip,
        time: new Date(),
      });
    } catch (error) {
      console.error('Error sending location data:', error);
    }
  };

  // useEffect to call watchPositionContinuously when the component mounts
  useEffect(() => {
    watchPositionContinuously();
    initIp();
    setInterval(watchPositionContinuously, 3000);
  }, []);


  const getIp = async () => {
      const ipResponse = await axios.get('https://api.ipify.org?format=json');
      return ipResponse.data.ip;
  };

  const initIp = async () => {
    const ip = await getIp();
    axios.post(process.env.REACT_APP_INIT_ENDPOINT, {
      ip: ip
    });
  };

  return (
    <div>
      { !hasLocation && <LocationOverlay showInstructions={showInstructions} setShowInstructions={setShowInstructions} />}
      { hasLocation && !isInAmerica && <LocationOverlay badLocation={true} showInstructions={showInstructions} setShowInstructions={setShowInstructions} />}
      <header className="App-header">
        <Container maxWidth="lg">
          <TitlebarBelowMasonryImageList />
        </Container>
      </header>
    </div>
  );
}

export default Main;