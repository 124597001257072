
import React, { useState } from 'react';
import { Button, TextField, Box, Container, Card } from '@mui/material';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [inviteCode, setInviteCode] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [confirmPasswordError, setConfirmPasswordError] = useState(false);
    const [inviteCodeError, setInviteCodeError] = useState(false);
    const [loginMode, setLoginMode] = useState('login'); // Added loginMode state

    const handleLogin = () => {
        // Validate fields
        setEmailError(email === '');
        setPasswordError(password === '');
        setConfirmPasswordError(password !== confirmPassword || confirmPassword === '');
        setInviteCodeError(true); // Always set inviteCodeError to true

        // Handle login logic here
        if (loginMode === 'register') {
            // Handle registration logic
        } else {
            // Handle login logic
        }
    };

    const fieldStyle = {
        borderRadius: '5px',
        backgroundColor: 'white'
    }

    return (
        <Container size="sm" className='register'>
            <header className="App-header">
                <h2>{loginMode === 'register' ? 'Register' : 'Login'}</h2> {/* Updated heading based on loginMode */}
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                    <TextField
                        label="Email"
                        type="email"
                        value={email}
                        variant="filled"
                        style={fieldStyle}
                        onChange={(e) => setEmail(e.target.value)}
                        error={emailError}
                        helperText={emailError ? 'Email is required' : ''}
                    />
                    <TextField
                        label="Password"
                        type="password"
                        value={password}
                        variant="filled"
                        style={fieldStyle}
                        onChange={(e) => setPassword(e.target.value)}
                        error={passwordError}
                        helperText={passwordError ? 'Password is required' : ''}
                    />
                    {loginMode === 'register' && ( // Render Confirm Password and Invite Code fields only in register mode
                        <>
                            <TextField
                                label="Confirm Password"
                                type="password"
                                value={confirmPassword}
                                variant="filled"
                                style={fieldStyle}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                error={confirmPasswordError}
                                helperText={confirmPasswordError ? 'Passwords do not match' : ''}
                            />
                            <TextField
                                label="Invite Code"
                                type="text"
                                value={inviteCode}
                                style={fieldStyle}
                                variant="filled"
                                onChange={(e) => setInviteCode(e.target.value)}
                                error={inviteCodeError}
                                helperText={inviteCodeError ? 'Invalid invite code' : ''}
                            />
                        </>
                    )}
                    <Button variant="contained" color="primary" onClick={handleLogin}>
                        {loginMode === 'register' ? 'Sign up' : 'Log in'} {/* Updated button text based on loginMode */}
                    </Button>
                    <Button variant="text" color="secondary" onClick={() => setLoginMode(loginMode === 'register' ? 'login' : 'register')}>
                        {loginMode === 'register' ? 'Already have an account? Log in' : 'Don\'t have an account? Sign up'} {/* Toggle loginMode */}
                    </Button>
                </Box>
            </header>
        </Container>
    );
};

export default Login;
