import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const ImageOverlay = ({ imageUrl, onClose }) => {
    return (
        <div
            style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(0, 0, 0, 0.85)',
                zIndex: 1000,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <button
                onClick={onClose}
                style={{
                    position: 'absolute',
                    top: '40px',
                    right: '40px',
                    padding: '5px',
                    backgroundColor: 'transparent',
                    color: '#fff',
                    border: 'none',
                    cursor: 'pointer',
                    fontSize: '20px',
                }}
                className='close-button'
            >
                <FontAwesomeIcon icon={faTimes} size='xl' />
            </button>
            <div
                style={{
                    position: 'relative',
                    maxWidth: '90%',
                    maxHeight: '90%',
                }}
            >
                <img
                    src={imageUrl}
                    alt="Overlay"
                    style={{
                        width: '100%',
                        height: '100%'
                    }}
                />
            </div>
        </div>
    );
};

export default ImageOverlay;
