import React from 'react';
import { Typography, Box, Container } from '@mui/material';

const About = () => {
    return (
            <header className="App-header">
                <Box className="about">
                    <h2>
                        About Image Casa
                    </h2>
                    <div className='about-inner'>
                    <Typography variant="body1" gutterBottom>
                        Image Casa is a unique image hosting service that provides a secure and private platform for sharing images. Unlike traditional image hosting platforms, Image Casa allows users to restrict access to their images based on specific locations.
                    </Typography>
                    <br/>
                    <Typography variant="body1" gutterBottom>
                        The benefits of using Image Casa are numerous. By restricting access to images based on location, users can ensure that their images are only viewable by individuals in certain areas. This can be particularly useful for sensitive or private images that are intended for a specific audience.
                    </Typography>
                    <br/>
                    <Typography variant="body1" gutterBottom>
                        Additionally, Image Casa offers a user-friendly interface and seamless integration with other services. Users can easily upload, organize, and manage their images, while also enjoying fast and reliable performance.
                    </Typography>
                    <br/>
                    <Typography variant="body1" gutterBottom>
                        Whether you're a photographer, a designer, or simply someone who values privacy, Image Casa is the perfect platform for hosting and sharing your images securely.
                    </Typography>
                    </div>
                </Box>
            </header>
    );
};

export default About;
