import React from 'react';
import NotListedLocationIcon from '@mui/icons-material/NotListedLocation';
import { Container, Icon, Button, ButtonGroup, Alert,  } from '@mui/material';
import { useState, useEffect } from 'react';
const LocationOverlay = (props) => {
    const [selectedPill, setSelectedPill] = useState('Computer');
    const handlePillClick = (pill) => {
        setSelectedPill(pill);
      };
    const computerInstructions = () => {
        return(
          <div>
              <p className='mb-0'>On your computer, open Chrome</p>
              <p className='mb-0'>{'At the top right, click More > Settings'}</p>
              <p className='mb-0'>{'Click Privacy and security > Site Settings'}</p>
              <p className='mb-0'>Click <strong>Location</strong></p>
              <p className='mb-0'>Choose the option you want as your default setting</p>
              <p className='mb-0'>Refresh page</p>
              </div>
        )
      }
    
      const androidInstructions = () => {
        return(
          <div>
              <p className='mb-0'>On your Android phone or tablet, open the Chrome app</p>
              <p className='mb-0'>{'At the top right of the address bar, tap More > Settings'}</p>
              <p className='mb-0'>{'Tap Site settings > Location'}</p>
              <p className='mb-0'>Turn <strong>Location</strong> on or off</p>
              <p className='mb-0'>Refresh page</p>
              </div>
        )
      }
    
      const iPhoneInstructions = () => {
        return(
          <div>
              <p className='mb-0'>On your iPhone or iPad, open the settings  app</p>
              <p className='mb-0'>Find and tap <strong>Chrome</strong></p>
              <p className='mb-0'>Tap <strong>Location</strong></p>
              <p className='mb-0'>Choose the option you want as your default setting</p>
              <p className='mb-0'>Refresh page</p>
              </div>
        )}
        
        const isActive = (pill) => {
            return selectedPill === pill ? 'true' : '';
            }
    return (
      <div className='overlay'>
        <div className='location-lock'>
          { props.badLocation ?
            <p>Sorry, this gallery can only be viewed in the United States of America.</p>
            :
            <div>
          <p>These images are location-locked. Please enable location services to view.
            <span onClick={() => props.setShowInstructions(!props.showInstructions)}>
            <NotListedLocationIcon />
          </span></p>
          {props.showInstructions &&
          <div>
            <div className={`instructions`}>
              <Alert color='info'>
                <p className='mb-0'>To enable location services:</p>

                <ButtonGroup className='my-2'>
                  <Button size='sm' outline="true" color="info" onClick={() => handlePillClick('Computer')} active={isActive('Computer')}>Computer</Button>
                  <Button size='sm' outline="true" color="info" onClick={() => handlePillClick('Android')} active={isActive('Android')}>Android</Button>
                  <Button size='sm' outline="true" color="info" onClick={() => handlePillClick('iPhone')} active={isActive('iPhone')}>iPhone</Button>
                </ButtonGroup>

                {selectedPill && (
                  selectedPill === 'Computer' ? computerInstructions() : selectedPill === 'Android' ? androidInstructions() : iPhoneInstructions()
                )}
              </Alert>
            </div>
          </div>
          }
          </div>
          }
        </div>
      </div>
    );
};

export default LocationOverlay;
