
import * as React from 'react';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import f1 from './images/f1.png';
import f2 from './images/f2.png';
import f3 from './images/f3.png';
import f4 from './images/f4.png';
import f5 from './images/f5.png';
import f6 from './images/f6.png';
import f7 from './images/f7.png';
import f8 from './images/f8.png';
import f9 from './images/f9.png';
import f10 from './images/f10.png';
import ImageOverlay from './ImageOverlay';

export default function TitlebarBelowMasonryImageList() {
  const [imgDisplay, setImgDisplay] = useState(false);
  const [imgUrl, setImgUrl] = useState('');
  const location = useLocation();
  const isFirstPage = location.pathname === '/albums/adelaney-04262024';
  const openImageInNewTab = (imageUrl) => {
    // window.open(imageUrl, '_blank');
    console.log('isFirstPage', isFirstPage);
    if (isFirstPage) {
      // open in new tab
      window.open('#albums/adelaney-4262024', '_blank');
    }
    else {
    setImgDisplay(true);
    setImgUrl(imageUrl);
    }
  };

  const noMargins = {
    margin: '10px 0 0 0',
  };

  return (
    <div className='image-list' style={{ width: '100%', overflow: 'hidden'}}>
    <div style={{ display: imgDisplay ? 'block' : 'none'}}>
      <ImageOverlay imageUrl={imgUrl} onClose={() => setImgDisplay(false)}/>
    </div>
    <h4 style={noMargins}>Foundation Damage</h4>
    <p>Author: Andrew Delaney</p>
    <p>Location: Pawtucket, RI 02860</p>
    <p>Date: 4/28/2024</p>
      <ImageList variant="masonry" cols={2} gap={8} style={{ overflow: 'hidden'}}>
        {itemData.map((item) => (
          <ImageListItem key={item.img} className='img'>
              <img
                srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                src={`${item.img}?w=248&fit=crop&auto=format`}
                alt={item.title}
                loading="lazy"
                onClick={() => openImageInNewTab(item.img)}
              />
          </ImageListItem>
        ))}
      </ImageList>
    </div>
  );
}

const itemData = [
  
  {
    img: f10,
    title: 'Image10',
    author: 'Andrew Delaney',
  },
  {
    img: f1,
    title: 'Image1',
    author: 'Andrew Delaney',
  },
  {
    img: f2,
    title: 'Image2',
    author: 'Andrew Delaney',
  },
  {
    img: f3,
    title: 'Image3',
    author: 'Andrew Delaney',
  },
  {
    img: f4,
    title: 'Image4',
    author: 'Christian Mackie',
  },
  {
    img: f5,
    title: 'Image5',
    author: 'Andrew Delaney',
  },
  {
    img: f6,
    title: 'Image6',
    author: 'Andrew Delaney',
  },
  {
    img: f7,
    title: 'Image7',
    author: 'Andrew Delaney',
  },
  {
    img: f8,
    title: 'Image8',
    author: 'Andrew Delaney',
  },
  {
    img: f9,
    title: 'Image9',
    author: 'Andrew Delaney',
  },
];