
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import logo from './imageCasa.png';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

export default function DenseAppBar() {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const redirectToAbout = () => {
    window.location.href = '/#about';
    handleClose();
  }

  const redirectToRegister = () => {
    window.location.href = '/#register';
    handleClose();
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar variant="dense">
          <IconButton edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} onClick={handleClick}>
            <MenuIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={redirectToRegister}>Login</MenuItem>
            <MenuItem onClick={redirectToAbout}>About</MenuItem>
          </Menu>
          <img src={logo} className="App-logo" alt="logo" />
          <span className='subtitle'>Secure image hosting</span>
        </Toolbar>
      </AppBar>
    </Box>
  );
}