import './App.css';
import DenseAppBar from './DenseAppBar';
import Login from './Login';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import About from './About';
import Main from './Main';

function App() {

  return (
    <div className="App">
    <DenseAppBar />
      <Router>
        <Routes>
          <Route path="/about" Component={About} />
          <Route path="/register" Component={Login} />
          <Route path="/" Component={Login} />
          <Route path="/albums/adelaney-4262024" exact Component={Main} />
          <Route path="/albums/adelaney-04262024" exact Component={Main} />
        </Routes>
      </Router>

    </div>
  );
}

export default App;
